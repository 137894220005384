header {
    display: flex;
    justify-content: flex-end;
    max-width: 90%;
    margin: 0 auto;
    padding: 30px 0;
}

.btn.nav {
    font-weight: 700;
    font-size: 1.1rem;
}
@media screen and (max-width: 540px) {
    header {
        padding: 30px 0;
    }
}
@media screen and (max-width: 376px) /* 375px */ {
    header {
        padding: 30px 0;
    }
}