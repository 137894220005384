body {
  /*background-image: url(https://firebasestorage.googleapis.com/v0/b/micro-fccdb.appspot.com/o/images%2FIMG_0346_edited_compressed.jpg?alt=media);*/
  background-size: auto;
  background-position: center center;
  height: 100%;
  background-repeat: no-repeat;
}


.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
}
.video-background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background: white;
}

.background-overlay {
  position: fixed;
  content: '';
  background: linear-gradient(180deg, rgba(51, 51, 51, 0.2) 8%, rgba(51, 51, 51, 0.5) 50%, #333333 95%);
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .video-background {
    /*position: absolute;
    object-fit: cover;
    min-width: inherit;
    min-height: inherit;
    width: 100vw;
    height: 100%;*/
    max-height: 100vh;
  }
}