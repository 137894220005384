.content .container {
    display: flex;   
}
.content .container.center {
    justify-content: center;
}
.container .logo {
    max-width: 20%;
    width: 100%;
}
h1.title {
    font-weight: 500;
    color: var(---primary-color);
    padding: 30px 0 30px 0;
    text-align: center;
}
h1.title b {
    font-weight: 900;
    color: var(---primary-color);
}
h3 b {
    color: var(---primary-color);
}
h3.message {
    text-align: center;
    font-weight: 500;
}
.thanks_message {
    padding-top: 30px;
    text-align: center;
}
.form {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 30px 0;
}

.form input.email {
    min-width: 200px;
}
.form button.submit {
    background: linear-gradient(90deg, #88B330 0%, #9ECD3D 100%);
    background-size: 100% 100%;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 15px 20px;
}
.form button.submit:hover {
    /*background: linear-gradient(90deg, #88B330 100%, #9ECD3D 100%);*/
    background-size: 200% 200%;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1024px) {
    .container .logo {
        max-width: 25%;
    }
}
@media screen and (max-width: 820px) {
    .container .logo {
        max-width: 55%;
    }
}
@media screen and (max-width: 767px) {
    h1.title {
        padding: 30px 0;
    }
    .container .logo {
        max-width: 40%;
    }
    .form {
        flex-wrap: wrap;
    }
    .form input.email {
        min-width: 0px;
        width: 100%;
    }
    .form button.submit {
        width: 100%;
    }
}
@media screen and (max-width: 540px) {
    .container .logo {
        max-width: 55%;
    }
}
@media screen and (max-width: 376px) /* 375px */ {
    h1.title {
        padding: 20px 0 20px 0;
    }
}