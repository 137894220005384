.bottom-container {
    border-top: solid 1px #ffffff;
}
.content.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 20px 0;
    
}
.footer .supported-text-container {
    min-width: 200px;
    display: flex;
}
.footer .supported-text-container p {
    align-self: center;
}
.footer .logos-container  {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}
.logos-container .logo-txt {
    font-weight: bold;
    align-self: center;
}
.logos-container .logo-img {
    max-width: 25%;
    align-self: center;
    max-height: 60px;
}

@media screen and (max-width: 767px) {
    .content.footer {
        flex-direction: column;  
    }
    .footer .logos-container {
        flex-wrap: wrap;
        padding: 15px 0;
        gap: 20px;
    }
}
@media screen and (max-width: 428px) {
    .logos-container .logo-img {
        max-width: 29%;
    }
}