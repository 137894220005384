* {
  position: relative;
  color: #ffffff;
  margin: 0;
  transition-duration: 0.3s;
}
html,
body {
  margin: 0;
  height: 100vh;
  min-height: 100vh;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Basis Grotesque Arabic Pro', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  ---primary-color: #86AF32;
  ---parrafo: #998181;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

a {
  text-decoration: none;
}
h1 {
  font-size: 3.5rem;
  line-height: 3.5rem;
}
h3 {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 2.2rem;
}
input {
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  outline: none;
  border: solid 2px transparent;
  color: var(---parrafo);
}
input:focus {
  border: solid 2px #86AF32;
}
button {
  font-size: 1.05rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }
  h3 {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 415px) /* 414px */ {
  h1 {
    font-size: 2.9rem;
  }
}
@media screen and (max-width: 412px) {
  h1 {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 394px) /* 393px */ {
  h1 {
    font-size: 2.7rem;
  }
  h3 {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 376px) /* 375px */ {
  h1 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}